<template>
  <div class="body" v-loading="loading">
    <!-- 未授权显示协议页 -->
    <template v-if="!platformPromotionAuth || isShowAgreement">
      <div class="text-content">
        <div class="title">《商会信息平台推广授权协议》</div>
        <div class="content">
          <p>
            本协议由使用本平台服务的您（以下简称 “用户”）与社团云平台（以下简称
            “平台”）共同缔结。在您完整阅读本协议内容后点击「同意并开通」，即表明您同意接受本协议的所有条款与条件约束，本协议自此对双方产生法律上的约束力。
          </p>

          <h4>一、授权内容</h4>
          <ol>
            <li>
              <strong>会员信息：</strong
              >具体涵盖但不限于商会会员的姓名、联系方式、所在企业名称、职务等详细信息。用户可自行斟酌并决定是否授权平台对该类信息予以使用及展示。
            </li>
            <li>
              <strong>资源信息：</strong
              >包括但不限于各类供应资源信息以及需求资源信息等。用户有权自主抉择是否将此类资源信息授权给平台用于推广展示用途。
            </li>
            <li>
              <strong>组织信息：</strong
              >涉及商会的组织架构详情、核心成员资料、发布的文章资讯等相关信息。用户能够依据自身意愿，通过勾选操作确定是否授权平台对该部分信息进行推广。
            </li>
            <li>
              <strong>活动信息：</strong
              >包含商会所举办的各类活动的详尽信息，诸如活动主题、举办时间、活动地点、参与人员要求等。用户可自行选择是否将活动信息授权给平台。
            </li>
          </ol>

          <h4>二、授权方式</h4>
          <p>
            用户在阅读并同意本协议后，系统将自动跳转至授权页面。用户可在该页面依照自身实际需求，以勾选的形式，清晰明确地选定授权给平台的具体信息内容。对于未被勾选的信息，平台将不会进行获取以及展示操作。
          </p>

          <h4>三、平台的权利与义务</h4>
          <ol>
            <li>
              <strong>权利：</strong
              >平台有权依据用户的有效授权，在「平台推广」专属页面展示相应的商会信息，以此达成商会信息的共通与推广目的。平台有权对所获取的商会信息实施必要的整理、分类以及编辑工作，旨在更好地进行展示与推广，但在处理过程中严禁歪曲、篡改信息原本的含义与意图。
            </li>
            <li>
              <strong>义务：</strong
              >平台务必严格遵循用户的授权范围来使用和展示商会信息，绝不可超出授权界限使用任何信息。平台负有保护用户授权信息安全的义务，需采用合理且有效的技术手段与管理措施，全力防止信息出现泄露、篡改或丢失等情形。平台应向用户提供页面装修功能，以便商会能够便捷地将「平台推广」页面配置到其所属小程序中，并且要确保该功能始终处于正常使用状态以及具备良好的稳定性。
            </li>
          </ol>

          <h4>四、用户的权利与义务</h4>
          <ol>
            <li>
              <strong>权利：</strong
              >用户有权在任何时间登录平台，查看并修改其授权给平台的信息内容以及授权状态。用户有权要求平台停止使用、删除其已授权但后续不再期望被展示的信息，平台应在接收到用户的合理请求后的适当时间内予以妥善处理。
            </li>
            <li>
              <strong>义务：</strong
              >用户应确保其授权给平台的信息真实可靠、准确无误且完整无缺，同时不得侵犯任何第三方的合法权益。用户应依照平台的规范要求，正确无误地进行授权操作。若因用户自身的操作失误而引发信息泄露或其他相关问题，相应责任由用户自行承担。
            </li>
          </ol>

          <h4>五、协议的变更与终止</h4>
          <ol>
            <li>
              平台基于业务发展的实际需求，有权适时对本协议进行修订与完善。在修改后的协议正式生效之前，平台将通过合理且恰当的方式向用户发出通知，若用户在收到通知后仍继续使用平台推广功能，则视为其已接受变更后的协议内容。
            </li>
            <li>
              用户享有随时终止本协议的权利，终止方式包括登录平台取消相关授权或者向平台发送书面通知。协议一旦终止，平台应立即停止使用用户的授权信息，并依据用户的要求对相关信息进行删除或者保留处理。
            </li>
            <li>
              倘若协议一方违反本协议的任何条款规定，另一方有权提前终止本协议，并依法要求违约方承担相应的违约责任。
            </li>
          </ol>

          <h4>六、违约责任</h4>
          <ol>
            <li>
              若平台违反本协议约定，超出授权范围使用用户信息或者未履行信息安全保护义务，从而致使用户遭受损失的，平台应承担相应的赔偿责任，赔偿范围涵盖用户因该违约行为所遭受的直接损失、间接损失以及可得利益损失等。
            </li>
            <li>
              若用户违反本协议约定，提供虚假信息或者未按照约定进行授权操作，给平台或其他第三方造成损失的，用户应承担全部赔偿责任，包括但不限于因处理虚假信息或错误授权所产生的额外成本、对第三方的赔偿费用以及平台声誉受损的补偿费用等。
            </li>
            <li>
              双方均应严格履行本协议约定的各项义务，如因一方违约给对方造成其他损失的，违约方应承担相应的法律责任，包括但不限于因违约行为引发的诉讼费用、律师费用以及其他合理的维权费用等。
            </li>
          </ol>

          <h4>七、隐私条款</h4>
          <ol>
            <li>
              平台将严格遵守相关法律法规，对用户的个人信息予以保密。在收集、使用、存储和传输用户信息时，仅基于本协议约定的授权目的以及为实现平台推广功能所必需的范围内进行操作。
            </li>
            <li>
              平台会采取必要的安全措施保护用户信息免受未经授权的访问、使用、修改或泄露。但因不可抗力或法律法规另有规定的除外。
            </li>
            <li>
              未经用户书面同意，平台不会将用户的个人信息向任何第三方披露或共享，除非是为了履行本协议项下的义务或法律法规要求的情形。
            </li>
            <li>
              用户有权随时查看平台对其个人信息的处理情况，并要求平台对其个人信息进行更正或删除。平台应在合理时间内响应并处理用户的请求。
            </li>
          </ol>

          <h4>八、争议解决</h4>
          <p>
            本协议的签订、履行、解释及争议解决均适用中华人民共和国法律。如双方在本协议履行过程中发生争议，应首先尝试通过友好协商方式解决；若协商未能达成一致，任何一方均有权向有管辖权的人民法院提起诉讼。
          </p>

          <h4>九、其他条款</h4>
          <p>
            本协议自用户点击「同意并开通」之日起正式生效，其有效期将持续至用户取消授权之日为止。在协议有效期内，双方应严格遵守协议约定，共同维护良好的合作关系与信息安全环境。
          </p>
        </div>
      </div>

      <FixedActionBar>
        <template v-if="!platformPromotionAuth">
          <el-button type="primary" @click="allowAgreement" :loading="loading"
            >同意协议</el-button
          >
        </template>
        <template v-if="platformPromotionAuth">
          <el-button @click="goBack" :loading="loading">返回</el-button>
          <el-button
            type="danger"
            @click="terminateAgreement"
            :loading="loading"
            >终止授权协议</el-button
          >
        </template>
      </FixedActionBar>
    </template>
    <!-- 已授权页面 -->
    <template v-else-if="!loading && platformPromotionAuth">
      <!-- flex-box -->
      <div class="flex-box">
        <el-button type="primary" @click="allClick">一键授权</el-button>

        <!-- 授权 -->
        <div class="allow-box">
          <el-checkbox :disabled="true" checked></el-checkbox>

          <el-link type="primary" @click="goToAgreement"
            >《商会信息平台推广授权协议》</el-link
          >
        </div>
      </div>

      <div class="auth-content">
        <div class="auth-title">授权内容：</div>

        <div class="auth-list">
          <el-row :gutter="42">
            <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
              <div class="auth-item">
                <el-collapse v-model="activeNames">
                  <el-collapse-item name="memberInfo">
                    <template slot="title">
                      <div class="title">
                        <el-checkbox
                          :indeterminate="
                            memberInfoCheckAll
                              ? false
                              : memberInfoIsIndeterminate
                          "
                          v-model="memberInfoCheckAll"
                          >会员信息</el-checkbox
                        >
                      </div>
                    </template>
                    <div class="list">
                      <div
                        class="check-item"
                        v-for="(item, i) in authInfo.member_info"
                        :key="i"
                      >
                        <el-checkbox
                          :disabled="item.disabled"
                          v-model="authInfo.member_info[i].value"
                          >{{ item.label }}</el-checkbox
                        >
                      </div>
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </div>
            </el-col>
            <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
              <div class="auth-item">
                <el-collapse v-model="activeNames">
                  <el-collapse-item name="cycCircleInfo">
                    <template slot="title">
                      <div class="title">
                        <el-checkbox
                          :indeterminate="
                            cycCircleInfoCheckAll
                              ? false
                              : cycCircleInfoIsIndeterminate
                          "
                          v-model="cycCircleInfoCheckAll"
                          >咨询信息</el-checkbox
                        >
                      </div>
                    </template>
                    <div class="list">
                      <div
                        class="check-item"
                        v-for="(item, i) in authInfo.cyc_circle_info"
                        :key="i"
                      >
                        <el-checkbox
                          :disabled="item.disabled"
                          v-model="authInfo.cyc_circle_info[i].value"
                          >{{ item.label }}</el-checkbox
                        >
                      </div>
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </div>
            </el-col>
            <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
              <div class="auth-item">
                <el-collapse v-model="activeNames">
                  <el-collapse-item name="organizationInfo">
                    <template slot="title">
                      <div class="title">
                        <el-checkbox
                          :indeterminate="
                            organizationInfoCheckAll
                              ? false
                              : organizationInfoIsIndeterminate
                          "
                          v-model="organizationInfoCheckAll"
                          >组织信息</el-checkbox
                        >
                      </div>
                    </template>
                    <div class="list">
                      <div
                        class="check-item"
                        v-for="(item, i) in authInfo.organization_info"
                        :key="i"
                      >
                        <el-checkbox
                          :disabled="item.disabled"
                          v-model="authInfo.organization_info[i].value"
                          >{{ item.label }}</el-checkbox
                        >
                      </div>
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </div>
            </el-col>
            <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
              <div class="auth-item">
                <el-collapse v-model="activeNames">
                  <el-collapse-item name="activityInfo">
                    <template slot="title">
                      <div class="title">
                        <el-checkbox
                          :indeterminate="
                            activityInfoCheckAll
                              ? false
                              : activityInfoIsIndeterminate
                          "
                          v-model="activityInfoCheckAll"
                          >活动信息</el-checkbox
                        >
                      </div>
                    </template>
                    <div class="list">
                      <div
                        class="check-item"
                        v-for="(item, i) in authInfo.activity_info"
                        :key="i"
                      >
                        <el-checkbox
                          :disabled="item.disabled"
                          v-model="authInfo.activity_info[i].value"
                          >{{ item.label }}</el-checkbox
                        >
                      </div>
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <FixedActionBar>
        <el-button type="primary" @click="saveDetail" :loading="loading"
          >确认授权</el-button
        >
      </FixedActionBar>
    </template>
  </div>
</template>

<script>
// 接口
import {
  getInfoAuth,
  saveInfoAuth,
  headerData,
  cancelAuth,
} from '../api/info-auth.js'
import FixedActionBar from '@/base/layout/FixedActionBar'

export default {
  components: { FixedActionBar },
  data() {
    return {
      loading: true,
      filterForm: {},
      // 详情数据
      detailData: '',
      // 处理后的授权对象
      authInfo: {
        member_info: [],
        cyc_circle_info: [],
        organization_info: [],
        activity_info: [],
      },
      activeNames: [
        'memberInfo',
        'cycCircleInfo',
        'organizationInfo',
        'activityInfo',
      ],
      // 是否是超级管理员
      isSuperAdmin: 0,
      // 是否已经授权过了
      platformPromotionAuth: 1,
      // 是否显示授权协议页
      isShowAgreement: false,
    }
  },
  watch: {},
  computed: {
    // 会员信息全选
    memberInfoCheckAll: {
      get() {
        let flag = this.authInfo.member_info.every((item) => item.value)
        return flag
      },
      set(val) {
        this.authInfo.member_info.forEach((item) => {
          if (item.disabled) {
            item.value = true
          } else {
            item.value = val
          }
        })
        console.log(val)
        return val
      },
    },
    // 咨询信息全选
    cycCircleInfoCheckAll: {
      get() {
        let flag = this.authInfo.cyc_circle_info.every((item) => item.value)
        return flag
      },
      set(val) {
        this.authInfo.cyc_circle_info.forEach((item) => {
          if (item.disabled) {
            item.value = true
          } else {
            item.value = val
          }
        })
        return val
      },
    },
    // 组织信息全选
    organizationInfoCheckAll: {
      get() {
        let flag = this.authInfo.organization_info.every((item) => item.value)
        return flag
      },
      set(val) {
        this.authInfo.organization_info.forEach((item) => {
          if (item.disabled) {
            item.value = true
          } else {
            item.value = val
          }
        })
        return val
      },
    },
    // 活动信息全选
    activityInfoCheckAll: {
      get() {
        let flag = this.authInfo.activity_info.every((item) => item.value)
        return flag
      },
      set(val) {
        this.authInfo.activity_info.forEach((item) => {
          if (item.disabled) {
            item.value = true
          } else {
            item.value = val
          }
        })
        return val
      },
    },
    // 会员信息
    memberInfoIsIndeterminate() {
      let flag = this.authInfo.member_info.some((item) => item.value)
      return flag
    },
    // 咨询信息
    cycCircleInfoIsIndeterminate() {
      let flag = this.authInfo.cyc_circle_info.some((item) => item.value)
      return flag
    },
    // 组织信息
    organizationInfoIsIndeterminate() {
      let flag = this.authInfo.organization_info.some((item) => item.value)
      return flag
    },
    // 活动信息
    activityInfoIsIndeterminate() {
      let flag = this.authInfo.activity_info.some((item) => item.value)
      return flag
    },
  },
  created() {
    this.getHeaderData()
  },
  methods: {
    // 跳转协议页面
    goToAgreement() {
      this.isShowAgreement = true
    },
    // 获取是否授权过，是否是管理员
    getHeaderData() {
      this.loading = true
      headerData()
        .then((res) => {
          this.isSuperAdmin = res.data.admin_user.is_super_admin
          this.platformPromotionAuth = res.data.platform_promotion_auth

          // 有授权才请求接口
          if (this.platformPromotionAuth) {
            this.getDetail()
          } else {
            this.loading = false
          }
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 一键授权
    allClick() {
      this.memberInfoCheckAll = true
      this.cycCircleInfoCheckAll = true
      this.organizationInfoCheckAll = true
      this.activityInfoCheckAll = true
      this.$nextTick(() => {
        this.saveDetail()
      })
    },
    // 数据对象转换数组函数
    transformToAuthInfo(param) {
      const result = {}
      // 遍历 auth_info 中的每一项
      for (const [category, permissions] of Object.entries(param.auth_info)) {
        result[category] = []

        // 遍历该分类下的所有字段
        for (const [key, value] of Object.entries(permissions)) {
          if (param.text[key]) {
            // 如果存在对应的 text 中的 label，构建新对象
            result[category].push({
              label: param.text[key], // 对应的 label 名称
              value: !!value, // 权限值（0或1） 转成 true or false
              disabled: ['nickname', 'name', 'region'].includes(key),
            })
          }
        }
      }

      return result
    },
    // 反向转换  数组转换对象函数
    transformBackToOriginalFormat(transformedData, text) {
      const result = {}

      // 遍历每个分类
      for (const [category, items] of Object.entries(transformedData)) {
        result[category] = {}

        // 遍历每个分类下的项
        for (const { label, value } of items) {
          // 查找对应的 text 中的 key
          const key = Object.keys(text).find((k) => text[k] === label)

          if (key) {
            // 将 label 和 value 存回对应的原始结构中
            result[category][key] = value ? 1 : 0
          }
        }
      }

      return result
    },
    // 获取详情
    getDetail() {
      this.loading = true
      getInfoAuth()
        .then((res) => {
          this.detailData = res.data
          this.authInfo = this.transformToAuthInfo(res.data)
          console.log(this.authInfo)
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 保存详情
    saveDetail() {
      this.loading = true
      console.log(this.authInfo, 'authInfo')
      // 参数
      let params = this.transformBackToOriginalFormat(
        this.authInfo,
        this.detailData.text
      )
      // id
      params.id = this.detailData.auth_info.id

      saveInfoAuth(params)
        .then((res) => {
          this.$message.success('授权成功')
          console.log(res)
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 同意协议
    allowAgreement() {
      if (!this.isSuperAdmin) {
        this.$message.warn('只有超级管理员可进行此操作')
        return
      }
      this.$confirm('是否同意协议？', '提示', {
        confirmButtonText: '同意',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.platformPromotionAuth = 1
          this.isShowAgreement = false
          this.getDetail()
        })
        .catch(() => {})
    },
    // 终止协议
    terminateAgreement() {
      if (!this.isSuperAdmin) {
        this.$message.warn('只有超级管理员可进行此操作')
        return
      }

      this.$confirm(
        '若终止本平台推广授权协议，确认后平台将停止使用您的相关信息进行推广展示，且您将无法再享受平台推广带来的信息互通等功能。',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then(() => {
          this.loading = true
          cancelAuth()
            .then((res) => {
              this.$message.success(res.msg)
              console.log(res)
              this.platformPromotionAuth = 0
              this.isShowAgreement = false
              this.loading = false
            })
            .catch((err) => {
              this.loading = false
            })
        })
        .catch(() => {})
    },
    // 返回
    goBack() {
      this.isShowAgreement = false
    },
  },
}
</script>

<style lang="scss" scoped>
.body {
  flex: 1;
}

.flex-box {
  display: flex;
  align-items: center;
  .allow-box {
    margin-left: 20px;
    display: flex;
    align-items: center;
  }
}
.auth-content {
  .auth-title {
    color: #000000;
    font-size: 16px;
    padding: 20px 0;
  }

  .auth-list {
    .auth-item {
      .title {
        background-color: #f5f7fa;
        padding-left: 12px;
        font-size: 16px;
        color: #303133;
        font-weight: bold;
      }

      .list {
        padding: 12px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 24px;
        .check-item {
          color: #303133;
          font-size: 14px;
        }
      }
    }
  }

  // 样式穿透折叠框
  ::v-deep.el-collapse {
    border: none;
    .el-collapse-item__header {
      background-color: #f5f7fa;
      border: none;
    }
    .el-collapse-item__wrap {
      border: none;
    }
  }
}

.text-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 24px;
  }
  .content {
    font-size: 18px;
    line-height: 32px;
    padding: 12px;
    h4 {
      font-size: 18px;
    }
    p {
      font-size: 18px;
      padding: 12px 0;
    }
  }
}
</style>
